.layout {
  color: #453663;
  background-color: #f6f2ee;
  margin: 0;
  padding: 0 20px;
  font: 1em / 1.4 freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.u-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

.u-container--narrow {
  max-width: 600px;
}

.title {
  color: #7d30e8;
  text-align: center;
  margin: 40px 0 20px;
  font: 900 2rem / 1.1 freight-text-pro, Big Caslon, Baskerville, Garamond, Cambria, Georgia, Times New Roman, serif;
}

@media only screen and (min-width: 768px) {
  .title {
    margin: 80px 0 40px;
    font-size: 2.25rem;
  }
}

.title--blue {
  color: #009bf4;
}

.title--learn-from-me {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .title--learn-from-me {
    text-align: left;
  }
}

.title--second-chance {
  color: #de1278;
  margin-top: 0;
}

.title-beta {
  text-align: center;
  margin: 40px 0 20px;
  font: 900 1.5rem / 1.2 freight-text-pro, Big Caslon, Baskerville, Garamond, Cambria, Georgia, Times New Roman, serif;
}

@media only screen and (min-width: 768px) {
  .title-beta {
    font-size: 1.75rem;
  }
}

.title-gamma {
  color: #28bd73;
  text-align: center;
  margin: 40px 0 20px;
  font: 600 1.375rem / 1.2 freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.u-visuallyhidden {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0 0 99.9% 99.9%);
  clip-path: inset(0 0 99.9% 99.9%);
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.u-nowrap {
  white-space: nowrap;
}

@media only screen and (min-width: 1280px) {
  .u-nowrap-at-xl {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 1024px) {
  .u-nowrap-at-l {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 768px) {
  .u-nowrap-at-m {
    white-space: nowrap;
  }
}

.u-underline {
  text-decoration: underline;
}

.u-no-underline {
  text-decoration: none;
}

.u-highlight {
  color: #4d12a0;
  font-weight: 600;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: #ffe270bf;
  text-decoration-color: #ffe270bf;
  text-decoration-thickness: 3px;
}

.u-highlight--alt {
  -webkit-text-decoration-color: #ed655940;
  text-decoration-color: #ed655940;
}

.u-highlight a:link, .u-highlight a:visited {
  -webkit-text-decoration-color: #0083ce54;
  text-decoration-color: #0083ce54;
}

.u-highlight a:hover {
  -webkit-text-decoration-color: #de1278;
  text-decoration-color: #de1278;
}

.u-highlight a:active {
  -webkit-text-decoration-color: #000;
  text-decoration-color: #000;
}

.u-amazon-logo {
  width: 51px;
  height: 16px;
  vertical-align: -7px;
  vertical-align: -webkit-baseline-middle;
  text-indent: -999em;
  background: url("amazon-logo.867511ba.svg") no-repeat;
  margin-left: .4em;
  font-size: 1px;
  line-height: 16px;
  display: inline-block;
  overflow: hidden;
}

.u-amazon-logo--reverse {
  background-image: url("amazon-logo-reverse.2547376c.svg");
}

.cta-button {
  color: #fff;
  -webkit-appearance: none;
  background-color: #f4257b;
  border: 0;
  border-radius: 5px;
  margin: 0;
  padding: 14px 25px 18px;
  font: 600 1.375rem / 1.2 freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-decoration: none;
  transition: all .15s ease-in-out;
  display: inline-block;
}

.cta-button_price {
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}

.cta-button del {
  color: #ffffffbf;
  text-decoration: line-through;
}

.cta-button ins {
  text-decoration: none;
}

.cta-button:hover, .cta-button:focus {
  background-color: #ff1a66;
  outline: none;
  transform: scale(1.03);
}

.cta-button:active {
  color: #ffffffd9;
  background-color: #de1278;
  transform: translateY(1px);
}

.u-checklist > li {
  background: url("icon-checkmark.cf63e11a.svg") 0 .45em no-repeat;
  padding-left: 20px;
  list-style: none;
}

.header {
  color: #fff;
  background-color: #651dc9;
  margin: 0 -20px;
  padding: 40px 20px;
}

@media only screen and (min-width: 375px) {
  .header {
    padding-bottom: calc(40px + 28vw);
  }
}

@media only screen and (min-width: 768px) {
  .header {
    padding-top: 80px;
    padding-bottom: calc(80px + 28vw);
  }
}

@media only screen and (min-width: 1280px) {
  .header {
    padding-top: 120px;
  }
}

.header_wrap {
  grid-template: "title"
                 "cover"
                 "cta"
                 / auto;
  align-items: center;
  gap: 20px;
  display: grid;
}

@media only screen and (min-width: 768px) {
  .header_wrap {
    grid-template-columns: 284px 1fr;
    grid-template-areas: "title title"
                         "cover cta";
    column-gap: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .header_wrap {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1280px) {
  .header_wrap {
    row-gap: 40px;
  }
}

.header_title {
  width: 100%;
  max-width: 697px;
  grid-area: title;
  margin: 0 auto;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.header_title:before {
  content: "";
  background: url("title-designing-in-figma.b809af62.svg") 0 0 / 100% no-repeat;
  padding-bottom: 11.1908%;
  display: block;
}

.header_title a {
  position: absolute;
  inset: 0;
}

.header_cta {
  text-align: center;
  grid-area: cta;
}

@media only screen and (min-width: 768px) {
  .header_cta {
    text-align: left;
    align-self: center;
  }
}

.header_subtitle {
  margin: 0 0 40px;
  font: 1.5em / 1.25 freight-text-pro, Big Caslon, Baskerville, Garamond, Cambria, Georgia, Times New Roman, serif;
}

@media only screen and (min-width: 768px) {
  .header_subtitle {
    margin-bottom: 20px;
    font-size: 1.75rem;
  }
}

.header_cover {
  grid-area: cover;
  justify-self: center;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .header_cover {
    place-self: center end;
  }
}

.header_cover > img {
  width: 100%;
  max-width: 233px;
  height: auto;
  vertical-align: middle;
  position: relative;
}

.header_cover:before {
  content: "";
  background: url("figma-book-cover-shadow@2x.74a75659.png") 0 0 / 100% no-repeat;
  margin-bottom: -4.6105%;
  padding-top: 18.4859%;
  position: absolute;
  bottom: 0;
  left: -10.9442%;
  right: -10.9442%;
}

.editions {
  grid-template-rows: auto;
  grid-template-columns: auto;
  place-items: center;
  gap: 10px;
  display: grid;
}

@media only screen and (min-width: 768px) {
  .editions {
    grid-template-columns: auto 1fr;
    justify-items: start;
    gap: 20px;
  }

  .editions_paperback .u-nowrap {
    display: block;
  }
}

.editions_paperback {
  text-align: left;
  margin: 0;
  line-height: 1.2;
}

.editions_paperback a:link, .editions_paperback a:visited {
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .editions--equal {
    grid-template-columns: 1fr 1fr;
  }

  .editions--equal .editions_ebook {
    justify-self: end;
  }
}

.limited-price {
  color: #ffea80;
  margin: 10px 0 0 -.55em;
  font-size: 1.125em;
}

@media only screen and (min-width: 768px) {
  .limited-price {
    font-size: 1.25em;
  }
}

.bulk-price {
  color: #ffea80;
  margin: 10px 0 0;
}

.bulk-price a:link, .bulk-price a:visited {
  color: inherit;
}

.bulk-price a:hover {
  color: #fff;
}

.shapoholic {
  margin: 0 -20px;
}

@media only screen and (min-width: 375px) {
  .shapoholic {
    background: url("shapes-bottom.152e24a3.svg") 50% 33% / 250% no-repeat, url("shapes-top.67e78177.svg") 50% 0 / 250% no-repeat;
    margin-top: calc(-40px - 28vw);
    padding: 40px 20px 0;
  }
}

@media only screen and (min-width: 768px) {
  .shapoholic {
    background-size: 120%, 120%;
    margin-top: calc(-80px - 28vw);
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .shapoholic {
    background-size: 100%, 100%;
    padding-top: 120px;
  }
}

@media only screen and (min-width: 1280px) {
  .shapoholic {
    background-position: 50%, 50% 0;
  }
}

.content {
  background-color: #fff;
  padding: 20px 20px 40px;
}

@media only screen and (min-width: 375px) {
  .content {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .content {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .content {
    border-radius: 20px;
  }
}

.text {
  font-size: 1.125rem;
  line-height: 1.5;
}

.text a:link, .text a:visited {
  color: #0083ce;
}

.text a:hover {
  color: #de1278;
}

.text a:active {
  color: #000;
}

.text p {
  margin: .6em 0 .8em;
}

.hr-title {
  color: #009bf4;
  margin: 40px -20px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .hr-title {
    margin-top: 80px;
  }
}

.hr-title:before {
  content: "";
  height: 2px;
  background-color: #009bf440;
  margin-top: -1px;
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
}

.hr-title_text {
  background-color: #fff;
  padding: 0 .5em;
  position: relative;
}

.toc {
  grid-template-columns: auto;
  column-gap: 40px;
  display: grid;
}

@media only screen and (min-width: 768px) {
  .toc {
    grid-template-columns: 1fr 1fr;
  }
}

.toc mark {
  color: #453663d9;
  background-color: #fff9b3;
  border-radius: 1px;
  margin-left: .33em;
  padding: .1em .33em;
  font-size: .875rem;
  display: inline-block;
}

.toc_lev1 {
  margin: 0 0 0 20px;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .toc_lev1 {
    margin-left: 0;
  }
}

.toc_lev1 > li {
  color: #45366380;
  margin: 0 0 1.5em;
  padding: 0;
}

.toc_lev1 > li > * {
  color: #453663;
}

.toc_lev1 a:link, .toc_lev1 a:visited {
  color: #0083ce;
}

.toc_lev1 a:hover {
  color: #de1278;
}

.toc_lev1 a:active {
  color: #000;
}

.toc_part {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-weight: 600;
}

.toc_lev2 {
  margin: .4em 0 0;
  padding: 0;
}

.toc_lev2 > li {
  margin: .25em 0;
  padding: 0;
  list-style: none;
}

.toc_sample {
  background-color: #fffcf5;
  border: 1px solid #f9dc85;
  margin: .5em 0 .75em;
  padding: 10px;
  font-size: .9375rem;
}

.toc_sample strong {
  color: #de1278;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin: 0 0 .15em;
  font-size: .75rem;
  display: block;
}

.essential-plugins {
  width: 100%;
  max-width: 495px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.whats-inside_wrap {
  grid-template-columns: auto;
  row-gap: 10px;
  display: grid;
}

@media only screen and (min-width: 768px) {
  .whats-inside_wrap {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 40px;
    margin: 0 -20px;
  }
}

.whats-inside_pages > img {
  width: 100%;
  max-width: 691px;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .whats-inside_pages {
    height: 245px;
    text-align: right;
    position: relative;
    overflow: hidden;
  }

  .whats-inside_pages > img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.whats-inside_list {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
}

@media only screen and (min-width: 768px) {
  .whats-inside_list {
    max-width: 440px;
    font-size: 1.25rem;
  }
}

.whats-inside_list li {
  margin: .5em 0;
}

.testimonials_wrap {
  grid-template-columns: auto;
  display: grid;
}

@media only screen and (min-width: 768px) {
  .testimonials_wrap {
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
}

.testimonial {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 40px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .testimonial {
    padding: 40px;
  }
}

.testimonial:after {
  content: "";
  z-index: -1;
  background-color: #7d30e8b3;
  border-radius: 14px;
  position: absolute;
  inset: 4px -4px -4px 4px;
}

.testimonial_quote {
  margin: 0;
  padding: 0;
  font: 1.125rem / 1.4 freight-text-pro, Big Caslon, Baskerville, Garamond, Cambria, Georgia, Times New Roman, serif;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .testimonial_quote {
    font-size: 1.25rem;
  }
}

.testimonial_quote:before {
  content: "“";
  color: #7d30e8;
  font-size: 4em;
  font-weight: 900;
  line-height: 1;
  position: absolute;
  top: -.33em;
  left: -.66em;
}

.testimonial_quote p {
  margin: 0;
  padding: 0;
}

.testimonial_quote mark {
  color: #2e2442;
  background-color: #fff9b3;
  padding-left: .2em;
  padding-right: .2em;
}

.testimonial_person {
  color: #7d30e8;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 10px;
  margin: 20px 0 0;
  line-height: 1.2;
  display: grid;
}

.testimonial_photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 .5px #00000026;
}

.testimonial_name a:link, .testimonial_name a:visited {
  color: inherit;
}

.testimonial--blue:after {
  background-color: #009bf4b3;
}

.testimonial--blue .testimonial_quote:before, .testimonial--blue .testimonial_person {
  color: #009bf4;
}

.testimonial--green:after {
  background-color: #28bd73b3;
}

.testimonial--green .testimonial_quote:before, .testimonial--green .testimonial_person {
  color: #28bd73;
}

.ef {
  float: right;
  width: 100px;
  height: 90px;
  margin: -1em 0 1em 10px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .ef {
    float: left;
    margin: -60px 0 0 -120px;
  }
}

@media only screen and (min-width: 1280px) {
  .ef {
    width: 130px;
    height: 117px;
    margin-top: -60px;
    margin-left: -160px;
  }
}

.ef:before {
  content: "";
  width: 85px;
  height: 85px;
  background: url("ef-bg.0dc01e90.svg") 0 0 / contain no-repeat;
  transition: transform 3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 1280px) {
  .ef:before {
    width: 113px;
    height: 113px;
  }
}

.ef:hover:before {
  transform: rotate(360deg);
}

.ef > img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 0 2px #f6f2ee54;
}

@media only screen and (min-width: 1280px) {
  .ef > img {
    width: 100px;
    height: 100px;
  }
}

.list-asterisks {
  margin: 0 0 0 24px;
  padding: 0;
  list-style: none;
}

@media only screen and (min-width: 1024px) {
  .list-asterisks {
    margin-left: 0;
  }
}

.list-asterisks > li {
  margin: 0 0 .8em;
  padding: 0;
  position: relative;
}

.list-asterisks > li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: url("li.1160cbe2.svg") 0 0 / 15px 15px no-repeat;
  margin-top: .3em;
  position: absolute;
  left: -24px;
}

.list-asterisks > li p {
  margin: 0 0 .5em;
}

.tweet {
  background-color: #fff;
  border: 1px solid #ccd6dd;
  border-radius: 10px;
  margin: 20px 0;
  padding: 20px;
  text-decoration: none;
  display: block;
  color: #000 !important;
}

.tweet_profile {
  grid-template-columns: 40px 1fr;
  grid-template-areas: "userpic fullname"
                       "userpic username";
  column-gap: 10px;
  display: grid;
}

.tweet_userpic {
  width: 40px;
  height: 40px;
  grid-area: userpic;
}

.tweet_fullname {
  grid-area: fullname;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
}

.tweet_username {
  color: #657787;
  grid-area: username;
  font-size: 1rem;
  line-height: 1.2;
}

.tweet_text {
  margin: 10px 0;
  line-height: 1.4;
}

.tweet_text .u-underline {
  color: #009bf4;
}

.tweet_time {
  color: #657787;
  font-size: 1rem;
}

.second-chance {
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 4px #f95d4eb3;
}

@media only screen and (min-width: 768px) {
  .second-chance {
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .second-chance {
    border-radius: 20px;
  }
}

.figmalion {
  color: #63c;
  background-color: #fee1d2;
  margin: 0 -20px;
  padding: 20px;
  font: 1.125rem / 1.3 freight-text-pro, Big Caslon, Baskerville, Garamond, Cambria, Georgia, Times New Roman, serif;
}

@media only screen and (min-width: 768px) {
  .figmalion {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 1.25rem;
  }
}

.figmalion a:link, .figmalion a:visited {
  color: inherit;
}

.figmalion_wrap {
  grid-template-columns: auto;
  align-items: center;
  row-gap: 10px;
  display: grid;
}

@media only screen and (min-width: 1024px) {
  .figmalion_wrap {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
}

.figmalion_text {
  text-align: center;
  order: 2;
}

@media only screen and (min-width: 1024px) {
  .figmalion_text {
    text-align: right;
    order: 1;
  }
}

.figmalion_logo {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .figmalion_logo {
    text-align: left;
    order: 1;
  }
}

.footer {
  color: #a198b3;
  text-align: center;
  margin-top: 40px;
  font: .875rem freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.footer a:link, .footer a:visited {
  color: inherit;
}

.chapter {
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  font: 1.125rem / 1.5 freight-text-pro, Big Caslon, Baskerville, Garamond, Cambria, Georgia, Times New Roman, serif;
}

@media only screen and (min-width: 768px) {
  .chapter {
    font-size: 1.25rem;
  }
}

.chapter a:link, .chapter a:visited {
  color: #0083ce;
}

.chapter a:hover {
  color: #de1278;
}

.chapter a:active {
  color: #000;
}

.chapter h2 {
  color: #000;
  font: 700 2rem / 1.1 freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media only screen and (min-width: 768px) {
  .chapter h2 {
    font-size: 2.25rem;
  }
}

.chapter_num {
  opacity: .3;
  font-weight: 500;
}

.chapter h3 {
  color: #000;
  margin: 2.5em 0 1.25em;
  font: 700 1.2em / 1.1 freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.chapter p {
  margin: .6em 0 .8em;
}

.chapter code {
  font: 1em freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.chapter_sample {
  color: #000;
  text-align: center;
  background-color: #fe9;
  border-radius: 5px;
  padding: 20px;
  font: 1.125rem freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.chapter_sample a:link, .chapter_sample a:visited {
  color: #000;
}

.chapter_sample a:hover, .chapter_sample a:active {
  color: #de1278;
}

.chapter_sample p {
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  .chapter_text {
    width: 100%;
    max-width: 440px;
  }
}

.back-home {
  font: 1rem freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #757575 !important;
}

.sidenote {
  margin: 1em auto;
}

@media only screen and (min-width: 1024px) {
  .sidenote {
    float: right;
    width: 200px;
    margin-top: .4em;
    margin-left: 0;
    margin-right: -240px;
  }
}

.sidenote_image {
  max-width: 100%;
  border: 1px solid #ddd;
}

.sidenote_image--halfsize {
  max-width: 50%;
}

.sidenote_image--borderless {
  border: 0;
}

.fullsize {
  margin: 20px 0;
}

@media only screen and (min-width: 1024px) {
  .fullsize {
    width: 680px;
    margin: 40px -240px 40px 0;
  }
}

.fullsize img {
  width: 100%;
  border: 1px solid #ddd;
}

.fullsize figcaption {
  color: #757575;
  margin: .25em 0 0;
  font: 1rem / 1.1 freight-sans-pro, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Helvetica, Arial, sans-serif;
}

/*# sourceMappingURL=index.7bb8e01f.css.map */
