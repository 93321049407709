// Fonts

$f-sans-serif-fb: system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$f-sans-serif: 'freight-sans-pro', $f-sans-serif-fb;

$f-serif-fb: 'Big Caslon', Baskerville, Garamond, Cambria, Georgia, 'Times New Roman', serif;
$f-serif: 'freight-text-pro', $f-serif-fb;


// Colors

$c-bg: #F6F2EE;
$c-text: #453663;
$c-purple: #7D30E8;
$c-purple-dark: #651DC9;
$c-red: #DE1278;
$c-pink: #F4257B;
$c-blue: #009BF4;
$c-grey: #757575;
$c-green: #28BD73;
$c-yellow: #FFEA80;
$c-link: darken($c-blue, 7.5%);
$c-link-hover: $c-red;
$c-link-active: #000;


// Layout (6 columns)

$grid-width: 120px;
$grid-gutter: 40px;

@function cols($num) {
  @return $grid-width * $num + $grid-gutter * ($num - 1);
}


// Spacing

$space-xl: 160px;
$space-l:  120px;
$space-m:   80px;
$space-s:   40px;
$space-xs:  20px;
$space-xxs: 10px;


// Pull the header to the middle of the top shapes
// (100vw * (Top shapes ratio: 672px / 1200px = 0.56)) / 2 = 28vw

$shapoholic-offset: 28vw;
