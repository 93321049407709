/* Header
---------------------------------------- */

@use "sass:math";

.header {
  margin: 0 -#{$space-xs};
  padding: $space-s $space-xs;
  background-color: $c-purple-dark;
  color: #FFF;

  @include mq-xs {
    padding-bottom: calc(#{$space-s} + #{$shapoholic-offset});
  }
  @include mq-m {
    padding-top: $space-m;
  	padding-bottom: calc(#{$space-m} + #{$shapoholic-offset});
  }
  @include mq-xl {
  	padding-top: $space-l;
  }

  &_wrap {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
    	"title"
    	"cover"
    	"cta";
    align-items: center;
    row-gap: $space-xs;
    column-gap: $space-xs;
  
    @include mq-m {
    	grid-template-columns: 284px 1fr;
    	grid-template-areas:
    	  "title title"
    	  "cover cta";
      column-gap: $grid-gutter;
    }
    @include mq-l {
      grid-template-columns: 1fr 1fr;
    }
    @include mq-xl {
      row-gap: $space-s;
    }
  }  

    &_title {
    	grid-area: title;

      position: relative;
      width: 100%;
      max-width: 697px; // SVG width
    	margin: 0 auto;
    	padding: 0;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        padding-bottom: (math.div(78px, 697px) * 100%);
        background: url('../images/title-designing-in-figma.svg') no-repeat 0 0 / 100% auto;
      }
      a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  
    // Subtitle & CTA

    &_cta {
    	grid-area: cta;
      text-align: center;

    	@include mq-m {
    	  align-self: center;
        text-align: left;
    	}
    }
      &_subtitle {
        margin: 0 0 $space-s;
        font: 1.5em/1.25 $f-serif; // 24px
    
        @include mq-m {
          margin-bottom: $space-xs;
          font-size: 1.75rem; // 28px
        }
      }

    // Cover

    &_cover {
      grid-area: cover;

      position: relative;
      justify-self: center;

      @include mq-m {
        align-self: center;
        justify-self: end;
    	}
    
    	& > img {
        // 233px x 308px
    	  position: relative;
    	  width: 100%;
        max-width: 233px;
    	  height: auto;
    	  vertical-align: middle;
    	}
    	&::before {
        // 284px x 52.5px
    	  content: '';
    	  position: absolute;
    	  left:  -(math.div((math.div(284px, 233px) * 100% - 100%), 2));
    	  right: -(math.div((math.div(284px, 233px) * 100% - 100%), 2));
    	  bottom: 0;
    	  margin-bottom: -4.6105%;
    	  padding-top: (math.div(52.5px, 284px) * 100%);
    	  background: url('../images/figma-book-cover-shadow@2x.png') no-repeat 0 0 / 100% auto;
    	}
    }
}

.editions {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
  gap: $space-xxs;

  @include mq-m {
    grid-template-columns: auto 1fr;
    justify-items: start;
    gap: $space-xs;

    &_paperback .u-nowrap {
      display: block;
    }
  }

  &_ebook {
    
  }
  &_paperback {
    margin: 0;
    line-height: 1.2;
    text-align: left;

    a:link,
    a:visited {
      color: inherit;
    }
  }

  // Second chance
  @include mq-m {
    &--equal {
      grid-template-columns: 1fr 1fr;
  
      .editions_ebook {
        justify-self: end;
      }
    }
  }
}

.limited-price {
  margin: $space-xxs 0 0 -.55em;
  color: $c-yellow;
  font-size: 1.125em; // 18px

  @include mq-m {
    font-size: 1.25em; // 20px
  }
}

.bulk-price {
  margin: $space-xxs 0 0;
  color: $c-yellow;

  a:link,
  a:visited {
    color: inherit;
  }
  a:hover {
    color: #FFF;
  }
}
