/* What readers are saying?
---------------------------------------- */

.testimonials {
  &_wrap {
    display: grid;
    grid-template-columns: auto;

    @include mq-m {
      grid-template-columns: 1fr 1fr;
      column-gap: $grid-gutter;
    }
  }
}

.testimonial {
  position: relative;
  margin-bottom: $space-xs;
  border-radius: 10px;
  padding: $space-xs $space-s;
  background-color: #FFF;

  @include mq-m {
    padding: $space-s;
  }

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    right: -4px;
    bottom: -4px;
    left: 4px;
    z-index: -1;
    border-radius: 14px;
    background-color: rgba($c-purple, .7);
  }

  &_quote {
    position: relative;
    margin: 0;
    padding: 0;
    font: 1.125rem/1.4 $f-serif;

    @include mq-m {
      font-size: 1.25rem;
    }

    &::before {
      content: "“";
      position: absolute;
      top: -.33em;
      left: -.66em;
      color: $c-purple;
      font-weight: 900;
      font-size: 4em;
      line-height: 1;
    }

    p {
      margin: 0;
      padding: 0;
    }
    mark {
      padding-right: .2em;
      padding-left: .2em;
      background-color: #FFF9B3;
      color: darken($c-text, 10%);
    }
  }

  &_person {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: $space-xxs;
    align-items: center;

    margin: $space-xs 0 0;
    color: $c-purple;
    line-height: 1.2;
  }
    &_photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0 0 0 .5px rgba(#000, .15);
    }
    &_name {
      a:link,
      a:visited {
        color: inherit;
      }
    }
}

.testimonial--blue {
  &::after {
    background-color: rgba($c-blue, .7);
  }
  .testimonial_quote::before {
    color: $c-blue;
  }
  .testimonial_person {
    color: $c-blue;
  }
}

.testimonial--green {
  &::after {
    background-color: rgba($c-green, .7);
  }
  .testimonial_quote::before {
    color: $c-green;
  }
  .testimonial_person {
    color: $c-green;
  }
}
