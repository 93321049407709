$chapter: 680px;
$chapter-text: 440px;
$chapter-aside: $chapter - $chapter-text - $grid-gutter;

.chapter {
  width: 100%;
  max-width: $chapter;
  margin: 0 auto;
  font: 1.125rem/1.5 $f-serif; // 18px

  @include mq-m {
    font-size: 1.25rem; //20px
  }

  a:link,
  a:visited {
    color: $c-link;
  }
  a:hover {
    color: $c-link-hover;
  }
  a:active {
    color: $c-link-active;
  }

  h2 {
    font: 700 2rem/1.1 $f-sans-serif;
    color: #000;

    @include mq-m {
      font-size: 2.25rem;
    }
  }
    &_num {
      opacity: .3;
      font-weight: 500;
    }
  
  h3 {
    margin: 2.5em 0 1.25em;
    font: 700 1.2em/1.1 $f-sans-serif;
    color: #000;
  }

  p {
    margin: .6em 0 .8em;
  }
  code {
    font: 1em $f-sans-serif;
  }


  &_sample {
    border-radius: 5px;
    padding: $space-xs $space-xs;
    background-color: #FFEE99;
    color: #000;
    text-align: center;
    font: 1.125rem $f-sans-serif;

    a:link,
    a:visited {
      color: #000;
    }
    a:hover,
    a:active {
      color: $c-link-hover;
    }
    p {
      margin: 0;
    }
  }
  &_text {
    @include mq-l {
      width: 100%;
      max-width: $chapter-text;
    }
  }
}

.back-home {
  font: 1rem $f-sans-serif;
  color: $c-grey !important;
}

.sidenote {
  margin: 1em auto;

  @include mq-l {
    float: right;
    width: $chapter-aside;
    margin-top: .4em;
    margin-right: -($chapter-aside + $grid-gutter);
    margin-left: 0;
  }

  &_image {
    max-width: 100%;
    border: 1px solid #DDD;

    &--halfsize {
      max-width: 50%;
    }
    &--borderless {
      border: 0;
    }
  }
}

.fullsize {
  margin: $space-xs 0;

  @include mq-l {
    width: $chapter;
    margin: $space-s 0;
    margin-right: -($chapter-aside + $grid-gutter);
  }

  img {
    width: 100%;
    border: 1px solid #DDD;
  }
  figcaption {
    margin: .25em 0 0;
    font: 1rem/1.1 $f-sans-serif;
    color: $c-grey;
  }
}
