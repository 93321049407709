/* Base
---------------------------------------- */

// Titles

.title {
  margin: $space-s 0 $space-xs;
  color: $c-purple;
  text-align: center;
  font: 900 2rem/1.1 $f-serif;

  @include mq-m {
    margin: $space-m 0 $space-s;
    font-size: 2.25rem;
  }

  &--blue {
    color: $c-blue;
  }
  &--learn-from-me {
    margin-bottom: $space-xs;

    @include mq-m {
      text-align: left;
    }
  }
  &--second-chance {
    margin-top: 0;
    color: $c-red;
  }
}

.title-beta {
  margin: $space-s 0 $space-xs;
  text-align: center;
  font: 900 1.5rem/1.2 $f-serif;

  @include mq-m {
    font-size: 1.75rem;
  }
}

.title-gamma {
  margin: $space-s 0 $space-xs;
  color: $c-green;
  text-align: center;
  font: 600 1.375rem/1.2 $f-sans-serif;
}



/* Helpers
---------------------------------------- */

.u-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
}


// Nowraps

.u-nowrap {
  white-space: nowrap;
}
.u-nowrap-at-xl {
  @include mq-xl {
    white-space: nowrap;
  }
}
.u-nowrap-at-l {
  @include mq-l {
    white-space: nowrap;
  }  
}
.u-nowrap-at-m {
  @include mq-m {
    white-space: nowrap;
  }
}


// Underlines

.u-underline {
  text-decoration: underline;
}
.u-no-underline {
  text-decoration: none;
}


// Highlights

.u-highlight {
  color: darken($c-purple, 20%);
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: rgba(#FFE270, .75);
  font-weight: 600;

  &--alt {
    text-decoration-color: rgba(#ed6559, .25);
  }

  a:link,
  a:visited {
    text-decoration-color: rgba($c-link, .33);
  }
  a:hover {
    text-decoration-color: $c-link-hover;
  }
  a:active {
    text-decoration-color: $c-link-active;
  }
}


// Amazon logo

.u-amazon-logo {
  display: inline-block;
  width: 51px;
  height: 16px;
  margin-left: .4em;
  background: url('../images/amazon-logo.svg') no-repeat;
  vertical-align: -7px;
  vertical-align: -webkit-baseline-middle;
  text-indent: -999em;
  line-height: 16px;
  font-size: 1px;
  overflow: hidden;

  &--reverse {
    background-image: url('../images/amazon-logo-reverse.svg');
  }
}


// Button

.cta-button {
  display: inline-block;
  margin: 0;
  border: 0;
  border-radius: 5px;
  padding: 14px 25px 18px;
  background-color: $c-pink;
  color: #FFF;
  font: 600 1.375rem/1.2 $f-sans-serif;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  -webkit-appearance: none;

  &_price {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
  }
  del {
    color: rgba(#FFF, .75);
    text-decoration: line-through;
  }
  ins {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    background-color: #FF1A66;
    outline: none;
    transform: scale(1.03);
  }
  &:active {
    background-color: $c-red;
    color: rgba(#FFF, .85);
    transform: translateY(1px);
  }
}


// Checklist

.u-checklist {
  & > li {
    padding-left: 20px;
    list-style: none;
    background: url("../images/icon-checkmark.svg") no-repeat 0 .45em;
  }
}
