// Second CTA

.second-chance {
  margin-top: $space-s;
  margin-bottom: $space-s;
  border-radius: 10px;
  padding: $space-xs;
  background-color: #FFF;
  color: #000;
  box-shadow: 0 4px 0 rgba(249, 93, 78, 0.7);
  text-align: center;

  @include mq-m {
    margin-top: $space-m;
    margin-bottom: $space-m;
    padding: $space-s;
  }
  @include mq-l {
    border-radius: 20px;
  }
}
