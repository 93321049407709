@mixin mq-xxxl {
  // Full-screen MBP 16"
  @media only screen and (min-width: 1536px) {
    @content;
  }
}
@mixin mq-xxl {
  // Landscape iPad Pro 12.9"
  @media only screen and (min-width: 1366px) {
    @content;
  }
}
@mixin mq-xl {
  // Half-screen iMac 27"; Full-screen MB 13"
  @media only screen and (min-width: 1280px) {
    @content;
  }
}
@mixin mq-l {
  // Landscape iPad
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
@mixin mq-m {
  // Portrait iPad
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin mq-s {
  // 11 @2x, 11 Pro Max @3x,
  @media only screen and (min-width: 414px) {
    @content;
  }
}
@mixin mq-xs {
  // iPhone SE @2x, X @3x, 11 Pro @3x
  @media only screen and (min-width: 375px) {
    @content;
  }
}
