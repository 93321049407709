/* Layout
---------------------------------------- */

.layout {
  margin: 0;
  padding: 0 $space-xs;
  background-color: $c-bg;
  color: $c-text;
  font: normal 1em/1.4 $f-sans-serif;
}

.u-container {
  box-sizing: border-box;
  width: 100%;
  max-width: cols(6);
  margin-right: auto;
  margin-left: auto;

  &--narrow {
    max-width: cols(4);
  }
}
