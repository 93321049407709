/* What's Inside
---------------------------------------- */

.whats-inside {
  &_wrap {
    display: grid;
    grid-template-columns: auto;
    row-gap: $space-xxs;

    @include mq-m {
      grid-template-columns: 1fr 1fr;
      column-gap: $grid-gutter;
      align-items: center;

      margin: 0 -#{$space-xs};
    }
  }
    &_pages {
      & > img {
        width: 100%;
        max-width: 691px;
        height: auto;
      }

      @include mq-m {
        position: relative;
        height: 245px;
        text-align: right;
        overflow: hidden;

        > img {
          position: absolute;
          top: 0;
          right: 0;
          width: auto;
          height: 100%;
        }
      }
    }
    &_list {
      margin: 0;
      padding: 0;
      font-size: 1.125rem;

      @include mq-m {
        max-width: cols(3);
        font-size: 1.25rem;
      }

      li {
        margin: .5em 0;
      }
    }
}
