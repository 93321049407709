// Figmalion

.figmalion {
  margin: 0 -#{$space-xs};
  padding: $space-xs;
  background-color: #FEE1D2;
  color: #63C;
  font: 1.125rem/1.3 $f-serif;

  @include mq-m {
    padding-top: $space-s;
    padding-bottom: $space-s;
    font-size: 1.25rem;
  }

  a:link,
  a:visited {
    color: inherit;
  }

  &_wrap {
    display: grid;
    grid-template-columns: auto;
    row-gap: $space-xxs;
    align-items: center;

    @include mq-l {
      grid-template-columns: 1fr 1fr;
      column-gap: $space-xs;
    }
  }
  &_text {
    order: 2;
    text-align: center;

    @include mq-l {
      order: 1;
      text-align: right;
    }
  }
  &_logo {
    text-align: center;

    @include mq-l {
      order: 1;
      text-align: left;
    }
  }
}
