/* Table of Contents
---------------------------------------- */

// Title

.hr-title {
  position: relative;
  margin: $space-s -#{$space-xs}; // Match horizontal padding of .content
  color: $c-blue;

  @include mq-m {
    margin-top: $space-m;
  }

  &::before {
    content: '';
    position: absolute;
    top: 55%;
    right: 0;
    left: 0;
    height: 2px;
    margin-top: -1px;
    background-color: rgba($c-blue, .25);
  }

  &_text {
    position: relative;
    padding: 0 .5em;
    background-color: #FFF;
  }
}


// ToC

.toc {
  display: grid;
  grid-template-columns: auto;
  column-gap: $grid-gutter;

  @include mq-m {
    grid-template-columns: 1fr 1fr;
  }

  mark {
    display: inline-block;
    margin-left: .33em;
    border-radius: 1px;
    padding: .1em .33em;
    background-color: #FFF9B3;
    color: rgba($c-text, .85);
    font-size: 0.875rem;
  }

  &_lev1 {
    margin: 0 0 0 $space-xs;
    padding: 0;

    @include mq-m {
      margin-left: 0;
    }

    & > li {
      margin: 0 0 1.5em;
      padding: 0;
      color: rgba($c-text, .5);

      > * {
        color: $c-text;
      }
    }
      a:link,
      a:visited {
        color: $c-link;
      }
      a:hover {
        color: $c-link-hover;
      }
      a:active {
        color: $c-link-active;
      }
    }
    &_part {
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
    &_lev2 {
      margin: .4em 0 0;
      padding: 0;

      & > li {
        margin: .25em 0;
        padding: 0;
        list-style: none;
      }
    }
      &_sample {
        margin: .5em 0 .75em;
        border: 1px solid #f9dc85;
        padding: $space-xxs;
        background-color: #FFFCF5;
        font-size: 0.9375rem;

        strong {
          display: block;
          margin: 0 0 .15em;
          color: $c-red;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
      }
}


// Plugins
// TODO: Replace with sprite

.essential-plugins {
  // 495px x 36px
  display: block;
  width: 100%;
  max-width: 495px;
  height: auto;
  margin: 0 auto;
}
