/* Why learn from me?
---------------------------------------- */

// Photo

.ef {
  position: relative;
  float: right;
  width: 100px;
  height: 90px;
  margin: -1em 0 1em $space-xxs;

  @include mq-m {
    float: left;
    margin: -60px 0 0 -120px;
  }
  @include mq-xl {
    width: 130px;
    height: 117px;
    margin-top: -60px;
    margin-left: -160px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 85px;
    height: 85px;
    background: url('../images/ef-bg.svg') no-repeat 0 0 / contain;
    transition: transform 3s ease-in-out;

    @include mq-xl {
      width: 113px;
      height: 113px;
    }
  }
  &:hover::before {
    transform: rotate(360deg);
  }

  > img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba($c-bg, .33);

    @include mq-xl {
      width: 100px;
      height: 100px;
    }
  }
}

// List

.list-asterisks {
  margin: 0 0 0 24px;
  padding: 0;
  list-style: none;

  @include mq-l {
    margin-left: 0;
  }

  > li {
    position: relative;
    margin: 0 0 .8em;
    padding: 0;

    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: -24px;
      margin-top: .3em;
      background: url('../images/li.svg') no-repeat 0 0 / 15px 15px;
    }

    p {
      margin: 0 0 .5em;
    }
  }
}


// Tweet

.tweet {
  display: block;
  margin: $space-xs 0;
  border: 1px solid #ccd6dd;
  border-radius: 10px;
  padding: $space-xs;
  background-color: #FFF;
  color: #000 !important;
  text-decoration: none;

  &_profile {
    display: grid;
    grid-template-areas:
      "userpic fullname"
      "userpic username";
    grid-template-columns: 40px 1fr;
    column-gap: $space-xxs;
  }
    &_userpic {
      grid-area: userpic;
      width: 40px;
      height: 40px;
    }
    &_fullname {
      grid-area: fullname;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2;
    }
    &_username {
      grid-area: username;
      color: #657787;
      font-size: 1rem;
      line-height: 1.2;
    }
  &_text {
    margin: $space-xxs 0;
    line-height: 1.4;

    .u-underline {
      color: $c-blue;
    }
  }
  &_time {
    color: #657787;
    font-size: 1rem;
  }
}
