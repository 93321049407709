// Footer

.footer {
  margin-top: $space-s;
  color: #A198B3;
  font: 0.875rem $f-sans-serif;
  text-align: center;

  a:link,
  a:visited {
    color: inherit;
  }
}
