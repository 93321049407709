/* Shapes
---------------------------------------- */

.shapoholic {
  margin: 0 -#{$space-xs};

  // See .header
  @include mq-xs {
  	margin-top: calc(-1 * (#{$space-s} + #{$shapoholic-offset}));
  	padding: $space-s $space-xs 0;
  	background: url('../images/shapes-bottom.svg') 50% 33% / 250% auto no-repeat,
  	            url('../images/shapes-top.svg')    50% 0   / 250% auto no-repeat;
  }
  @include mq-m {
  	margin-top: calc(-1 * (#{$space-m} + #{$shapoholic-offset}));
  	padding-top: $space-m;
  	background-size: 120% auto, 120% auto;
  }
  @include mq-l {
    padding-top: $space-l;
  	background-size: 100% auto, 100% auto;
  }
  @include mq-xl {
    background-position: 50% 50%, 50% 0;
  }
}


/* Content
---------------------------------------- */

.content {
  padding: $space-xs $space-xs $space-s;
  background-color: #FFF;

  @include mq-xs {
    border-radius: 10px;
  }
  @include mq-m {
    padding-top: $space-s;
    padding-bottom: $space-m;
  }
  @include mq-l {
	  border-radius: 20px;
  }
}

.text {
  font-size: 1.125rem; // 18px
  line-height: 1.5;

  a:link,
  a:visited {
	  color: $c-link;
  }
  a:hover {
	  color: $c-link-hover;
  }
  a:active {
	  color: $c-link-active;
  }

  p {
	  margin: .6em 0 .8em;
  }
}
